const config = {};

config.marketingUrl = 'https://staging.liberty-rider.com';
config.sharingUrl = 'https://staging.rider.live';
config.apiUrl = process.env.RAZZLE_API_URL || 'https://staging.api.liberty-rider.com/graphql';
config.trackApiUrl = 'https://staging.track-api.liberty-rider.com';
config.emergencyApiUrl = 'https://staging.ecall.liberty-rider.com';

config.firebase = {};
config.firebase.clientConfig = {
  apiKey: 'AIzaSyDc-kG6DyVZn_Zhk96ufXG9QZAPXIQGdEU',
  authDomain: 'staging.firebase.liberty-rider.com',
  databaseURL: 'https://libertyriderdev.firebaseio.com',
  projectId: 'libertyriderdev',
  storageBucket: 'libertyriderdev.appspot.com',
  messagingSenderId: '828786653990',
};
config.firebase.emergencyBucket = 'staging-sdk-emergency';
config.firebase.debugBucket = 'staging-sdk-debug';

config.mainLanguages = ['en', 'fr', 'nl', 'it']; // Ordered by priority.

config.analytics = {};
config.analytics.googleAnalyticsId = 'G-PXZ2LVXP2K';
config.analytics.enable = false;

config.jawgMaps = {};
config.jawgMaps.key = 'qqQCyrn6EMoS2Xp7Vl9UCDW9ZX1KmP3UTlSTOmZLBdbV4w3R8n06jTtqxqQW51Os';

config.openmaptiles = {};
config.openmaptiles.url = 'https://staging.tiles.liberty-rider.com/styles/osm-liberty/style.json';

config.mapbox = {};
config.mapbox.key = 'pk.eyJ1IjoibGliZXJ0eXN0YWdpbmciLCJhIjoiY2tqYTFjb3N5MjNxcjJwbnZyMnZiNnl2biJ9.cZc-8CzTAIH1fdEnVGTlog';

config.logging = {};
config.logging.metricsNamespace = false;
config.logging.monitorHttpStatus = false;
config.logging.addTraceId = false;

config.sentry = {};
config.sentry.dsn = 'https://a3ada6c3e8734a70b544527971d92d6a@sentry.io/1468845';
config.sentry.enabled = false;

config.forestUrlRide = 'https://app.forestadmin.com/Liberty%20Admin/Staging/Operators/data/2117628/index/record/2117628/';
config.forestUrlUser = 'https://app.forestadmin.com/Liberty%20Admin/Staging/Operators/data/2117637/index/record/2117637/';
config.metabaseEvents = 'https://metabase.liberty-rider.com/question/544';

module.exports = config;
