import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function HttpStatus({ code, children }) {
  return (
    // https://reacttraining.com/react-router/web/guides/server-rendering/404-401-or-any-other-status
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.httpStatus = code; // eslint-disable-line no-param-reassign
        }
        return children;
      }}
    />
  );
}
HttpStatus.propTypes = {
  code: PropTypes.number.isRequired,
  children: PropTypes.element.isRequired,
};
HttpStatus.defaultProps = {
};

export default HttpStatus;
