const config = require('./config.default');

config.analytics.enable = true;

config.sentry.enabled = true;

config.logging.metricsNamespace = process.env.METRICS_NAMESPACE || 'ride-react.staging';
config.logging.monitorHttpStatus = true;
config.logging.addTraceId = true;

module.exports = config;
