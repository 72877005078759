import React from 'react';
import styled from '@emotion/styled';

import config from 'config';

import logoAndText from './liberty-rider-logo-and-text.png';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.0001) 11.45%, rgba(255, 255, 255, 0.921575) 98.52%);
`;
const LogoAndText = styled.img`
  pointer-events: auto;
  margin-top: 8px;
  margin-left: 8px;
`;

export default function LogoAndGradient() {
  return (
    <Container>
      <a href={config.marketingUrl}>
        <LogoAndText alt="Liberty Rider" src={logoAndText} />
      </a>
    </Container>
  );
}

LogoAndGradient.propTypes = {
};

LogoAndGradient.defaultProps = {
};
