import React, {
  useContext, useState, useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { setAuthTokenForHttp } from 'services/global-auth-state';

const AuthContext = React.createContext(null);

// FIXME: simplify ; the difficulty is that apollo client needs the auth token,
// but it can't reference Firebase auth because it is only loaded on the admin.
// Solution: put the auth provider higher in the hierarchy than the apllo provider?
// The only reason that roadbook-react doesn't have to is that the Firebase SDK is
// behaving link a higher-up auth container.
// But there is a cyclic dependency because the auth can reset the apollo store.

export function AuthProvider({ children }) {
  // This component is placed near the root of the hierarchy, to trigger a re-render
  // of all child components that care about the authentication status.
  // It ensures that they all react to the same event, so that they stay synchronized.

  const [authState, setAuthState] = useState({ ready: false, user: null });

  const setFirebaseUser = useCallback((firebaseUser) => {
    // Save the auth token in a global context because apollo client needs it.
    // Set the token BEFORE updating the state because children might make http requests.
    setAuthTokenForHttp(firebaseUser ? firebaseUser.authToken : null);
    setAuthState({ ready: true, user: firebaseUser });
  }, []);

  const value = useMemo(() => ({
    ...authState,
    setFirebaseUser,
  }), [authState, setFirebaseUser]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useAuthContext() {
  const { user, ready, setFirebaseUser } = useContext(AuthContext);
  return { user, ready, setFirebaseUser };
}
