import 'intl-pluralrules';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import config from 'config';

export default null;

export function initializeLanguage(options = {}) {
  if (i18n.isInitialized) {
    // Happens during hot reload.
    console.log('i18n was already initialized'); // eslint-disable-line no-console
    return Promise.resolve();
  }

  return i18n
    .use(initReactI18next)
    .init({
      supportedLngs: config.mainLanguages,
      fallbackLng: 'fr', // Missing strings. Can also be an object of arrays.
      react: {
        useSuspense: false,
      },
      returnEmptyString: false,
      interpolation: { escapeValue: false }, // React would escape html entities twice.
      load: 'languageOnly', // No region specific locals like nl-BE are in the whitelist.
      ...options,
      // debug: true,
    });
}
