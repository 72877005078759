import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable'; // For IE 11 support
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { useSSR } from 'react-i18next';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { loadableReady } from '@loadable/component';
import ReactGA from 'react-ga4';

import { getAuthTokenForHttp } from 'services/global-auth-state';
import { initializeLanguage } from 'services/i18n';
import { AuthProvider } from 'components/AuthContext';

import App from './App';
import config from './config';
import { possibleTypes } from './apollo';

function createIdentificationLink({ name, version, traceId }) {
  const newHeaders = {};
  if (name) {
    newHeaders['Liberty-Client-Name'] = name;
  }
  if (version) {
    newHeaders['Liberty-Client-Version'] = version;
  }
  if (traceId) {
    newHeaders['X-Amzn-Trace-Id'] = traceId;
  }
  return setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...newHeaders,
    },
  }));
}

function InitI18nFromSSR({ initialI18nStore, initialLanguage, children }) {
  initializeLanguage({
    backend: {
      loadPath: '/ride-react/locales/ride-react-{{lng}}.json',
    },
    lng: window.initialLanguage,
  });
  useSSR(initialI18nStore, initialLanguage);
  return children;
}

async function hydrateApp() {
  await loadableReady();

  const authLink = setContext(async (_, { headers }) => {
    const jwtoken = getAuthTokenForHttp();
    if (jwtoken) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${jwtoken}`,
        },
      };
    }
    return {
      headers,
    };
  });

  const link = ApolloLink.from([
    authLink,
    createIdentificationLink({ name: 'ride-react client' }),
    createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
    new HttpLink({ uri: config.apiUrl }),
  ]);

  const client = new ApolloClient({
    // ssrForceFetchDelay: 100,
    link,
    // connectToDevTools: true,
    // Initialize the cache with the data from the server's cache.
    cache: new InMemoryCache({
      possibleTypes,
    }).restore(window.__APOLLO_STATE__),
  });

  const cache = createCache({ key: 'lr' });

  ReactGA.initialize(config.analytics.googleAnalyticsId, {
    // To debug, go to GA4 > Admin > Debug View, and open:
    // https://tagassistant.google.com/?authuser=0
    testMode: !config.analytics.enable, // Accumulate events but don't send them.
  });

  hydrateRoot(
    document.getElementById('root'),
    <CacheProvider value={cache}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <AuthProvider>
            <InitI18nFromSSR
              initialI18nStore={window.initialI18nStore}
              initialLanguage={window.initialLanguage}
            >
              <App />
            </InitI18nFromSSR>
          </AuthProvider>
        </BrowserRouter>
      </ApolloProvider>
    </CacheProvider>,
  );
}

hydrateApp();

if (module.hot) {
  module.hot.accept();
}
