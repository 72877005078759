import React from 'react';
import styled from '@emotion/styled';

const Box = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  margin: 0 10px;

  @keyframes spinner {
    to { transform: rotate(360deg); }
  }

  :before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
  }
`;

export default function Spinner() {
  return (
    <Box />
  );
}
