import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import Spinner from 'components/Spinner';

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

export default function PageLoading() {
  const [pastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setPastDelay(true), 300);
    return () => clearTimeout(timer);
  }, []);

  return pastDelay && (
    <Container>
      {pastDelay && <Spinner />}
    </Container>
  );
}

PageLoading.propTypes = {
};

PageLoading.defaultProps = {
};
