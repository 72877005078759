import React from 'react';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageLoading from 'components/PageLoading';
import HttpStatus from 'components/HttpStatus';
import RideError from 'components/RideError';
import LogoAndGradient from 'components/RideViewLayout/LogoAndGradient';

import config from 'config';
import './theme.css';

const withLoading = { fallback: <PageLoading /> };
const RideSharingPage = loadable(() => import('containers/RideSharingPage'), withLoading);
const ImaCockpitPage = loadable(() => import('containers/ImaCockpitPage'), withLoading);
const MapAdminPage = loadable(() => import('containers/MapAdminPage'), withLoading);

export default function App() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Switch>
        <Route
          path="/p/:sharingToken" // Redirect legacy route to avoid i18next crashes with robots ('p' is not a structurally valid language tag).
          render={({ match }) => <Redirect to={`/fr/p/${match.params.sharingToken}`} />}
        />
        <Route
          path="/(fr/|nl/|en/|it/)p/:sharingToken"
          render={({ match }) => <RideSharingPage sharingToken={match.params.sharingToken} />}
        />
        <Route
          path="/(fr/|nl/|en/|it/)a/:userCurrentRideSharingToken"
          render={({ match }) => (
            <RideSharingPage
              userCurrentRideSharingToken={match.params.userCurrentRideSharingToken}
            />
          )}
        />
        <Route
          path="/(fr/|nl/|en/|it/)?cockpit"
          render={({ location }) => {
            // FIXME: receive the token in the URL hash fragment for security.
            const query = new URLSearchParams(location.search);
            return query.get('token')
              ? <ImaCockpitPage token={query.get('token')} /> : 'The token parameter is mandatory in the URL.';
          }}
        />
        <Route
          path="/(fr/|nl/|en/|it/)?admin"
          render={({ location }) => (
            <MapAdminPage
              rideIdParam={new URLSearchParams(location.search).get('ride_id')}
              sharingTokenParam={new URLSearchParams(location.search).get('sharing_token')}
              sessionIdsParam={new URLSearchParams(location.search).get('session_ids')}
            />
          )}
        />
        <Route
          path="/:lng/e/:eid"
          exact
          render={({ match }) => {
            let form = 'tI8b1wmc'; // 'fr'
            if (match.params.lng === 'nl') {
              form = 'cNVHhzhH';
            } else if (match.params.lng === 'it') {
              form = 'mzlwJxZl';
            }
            return <Redirect to={`https://liberty-rider.typeform.com/to/${form}#eid=${match.params.eid}`} />;
          }}
        />
        <Route
          path="/"
          exact
          render={() => <Redirect to={config.marketingUrl} />}
        />
        <HttpStatus code={404}>
          <>
            <LogoAndGradient />
            <RideError />
          </>
        </HttpStatus>
      </Switch>
    </>
  );
}
