import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import noRideImage from './noride.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 500px;
  width: 100%;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
`;
const Image = styled.img`
  content: url(${noRideImage});
  width: 145px;
  height: 145px;
`;
const MainText = styled.div`
  font-size: 35px;
  font-weight: 800;
  line-height: 1.09;
  text-align: center;
  color: #000000;
`;
const SubText = styled.div`
  font-size: 15px;
  line-height: 1.47;
  text-align: center;
  color: #000000;
`;

function RideError() {
  const { t } = useTranslation();
  return (
    <Container>
      <Image />
      <MainText>{t('ride_not_found_title')}</MainText>
      <SubText>{t('ride_not_found_help')}</SubText>
    </Container>
  );
}

export default RideError;
