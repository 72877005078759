const env = process.env.RAZZLE_LIBERTY_ENV || 'development';
let config = null; // eslint-disable-line import/no-mutable-exports

switch (env) {
  case 'api-test':
    config = require('./config.api-test'); // eslint-disable-line global-require
    break;
  case 'development':
    config = require('./config.development'); // eslint-disable-line global-require
    break;
  case 'staging':
    config = require('./config.staging'); // eslint-disable-line global-require
    break;
  case 'production':
    config = require('./config.production'); // eslint-disable-line global-require
    break;
  default:
    throw new Error(`Unrecognized environment ${env}`);
}

export default config;
